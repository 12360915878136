import patient from '@/modules/helperPatient'
import {isUndefined,cloneDeep} from 'lodash'

export default {
  data: ()=>({
    currentPatient: undefined,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    },
    patient: {
      type: Object,
      default: undefined
    }
  },
  watch: {
    hn: function(newVal) {
      this.loadPatient(newVal)
    },
    patient: {
      handler() {
        this.currentPatient = cloneDeep(this.patient)
        if (isUndefined(this.currentPatient)) this.$emit('patientLoaded', this.currentPatient)
      },
      deep: true
    },
  },
  methods: {
    loadPatient(hn) {
      patient.getPatientByHn(hn).then((returnData)=>{
        this.currentPatient = returnData
        this.$emit('patientLoaded', returnData)
        this.$forceUpdate()
      }).catch((error)=>{
        this.reset()
      })
    },
    reset() {
      this.currentPatient = undefined
      this.$emit('patientUnloaded')
    },
    onPatientLoaded(patient) {
      this.currentPatient = patient
      this.$emit('patientLoaded', patient)
    },
    onPatientUnloaded(hn) {
      this.reset()
    }
  },
  mounted() {
    if (!isUndefined(this.hn)) this.loadPatient(this.hn)
    if (!isUndefined(this.patient)) this.currentPatient = cloneDeep(this.patient)
  }
}
